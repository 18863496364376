<template>
  <tp-modal max-width="640px" name="modal-preview-image" width="70%">
    <v-img :src="imageUrl" alt="123213" srcset=""></v-img>
  </tp-modal>
</template>

<script>
export default {
  props: {
    url: String
  },

  computed: {
    imageUrl() {
      return this.url;
    }
  }
};
</script>
