var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{staticClass:"my-0"},[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('div',{staticClass:"gallery pb-3"},[_c('div',[_c('div',{staticClass:"font-weight-bold mb-2"},[_vm._v("Video")]),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('div',{staticClass:"video-wrapper",on:{"click":_vm.handleOpenModalInsertVideo}},[_c('div',{staticClass:"gallery-add rounded"},[(!_vm.product.youtube_url)?_c('v-icon',{staticClass:"grey--text text--lighten-1",attrs:{"large":""}},[_vm._v("mdi-plus ")]):_c('v-img',{attrs:{"src":_vm.getYoutubeThumbnail(_vm.product.youtube_url),"alt":"youtube-thumbnail"}}),_c('v-fade-transition',[(hover && _vm.product.youtube_url)?_c('v-row',{staticClass:"fill-height grey darken-4 ma-0 rounded",staticStyle:{"opacity":"0.65","position":"absolute","width":"100%"},attrs:{"align":"center","justify":"center"}},[_c('v-btn',{attrs:{"color":"white","icon":""}},[_c('v-icon',[_vm._v("mdi-eye-outline")])],1),_c('v-btn',{attrs:{"color":"white","icon":""},on:{"click":_vm.removeVideo}},[_c('v-icon',[_vm._v("mdi-delete-outline")])],1)],1):_vm._e()],1)],1)])]}}])})],1),_c('div',{staticClass:"ml-4 overflow-auto"},[_c('div',{staticClass:"font-weight-bold mb-2 d-flex"},[_c('span',[_vm._v("Bộ ảnh")]),_c('image-processing-guide')],1),_c('div',{staticClass:"d-flex"},[_c('t-upload-image',{attrs:{"max-file-size":700 * 1024,"min-size":{ width: 2000, height: 1125 },"short-pixel-api-keys":_vm.apiKeys,"target-size":{ width: 2000, height: 2000 },"allow-compare-image":"","allow-compress":"","allow-file-dimension-validation":"","allow-file-size":"","allow-file-type-validation":"","allow-resize-file":"","required":""},on:{"change":_vm.onChangeImage},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var on = ref.on;
              var isCompressing = ref.isCompressing;
return [_c('fieldset',_vm._g({staticClass:"gallery-add rounded",attrs:{"disabled":isCompressing}},on),[_c('v-btn',{attrs:{"loading":isCompressing,"icon":""}},[_c('v-icon',{staticClass:"grey--text text--lighten-1",attrs:{"large":""}},[_vm._v("mdi-plus ")])],1)],1)]}}])}),_c('draggable',_vm._b({directives:[{name:"horizontal-scroll",rawName:"v-horizontal-scroll"}],staticClass:"ml-2 preview-gallery",model:{value:(_vm.product.images),callback:function ($$v) {_vm.$set(_vm.product, "images", $$v)},expression:"product.images"}},'draggable',_vm.dragOptions,false),_vm._l((_vm.product.images),function(item,index){return _c('v-hover',{key:index,staticClass:"cursor-move",scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var hover = ref.hover;
return [_c('div',{staticClass:"gallery-item"},[_c('v-img',{staticClass:"grey lighten-3 rounded tp-boder-thin mr-2",attrs:{"aspect-ratio":1,"src":item && item.slice(0, 5) !== 'blob:' ? item : undefined,"height":"120px","width":"120px"},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"color":"grey lighten-5","indeterminate":""}})],1)]},proxy:true}],null,true)},[_c('v-fade-transition',[(hover)?_c('v-row',{staticClass:"fill-height grey darken-4 ma-0",staticStyle:{"opacity":"0.65"},attrs:{"align":"center","justify":"center"}},[_c('v-btn',{attrs:{"color":"white","icon":""},on:{"click":function($event){return _vm.previewImage(item)}}},[_c('v-icon',[_vm._v("mdi-eye-outline")])],1),_c('v-btn',{attrs:{"color":"white","icon":""},on:{"click":function($event){return _vm.removeImage(item)}}},[_c('v-icon',[_vm._v("mdi-delete-outline")])],1)],1):_vm._e()],1)],1)],1)]}}],null,true)})}),1)],1)])])])],1),_c('modal-preview-image',{attrs:{"url":_vm.selectedImage}}),_c('modal-insert-video',{attrs:{"url":_vm.product.youtube_url},on:{"onInserted":_vm.handleInsertedVideo}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }