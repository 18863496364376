<template>
  <v-dialog
    v-model="visible"
    :retain-focus="false"
    :width="'900px'"
    max-width="90%"
  >
    <template #activator>
      <v-btn icon @click="toggle">
        <v-icon>mdi-cog</v-icon>
      </v-btn>
    </template>

    <v-toolbar
      class="px-5 pb-0 align-start"
      extension-height="40px"
      flat
      height="56x"
    >
      <v-toolbar-title class="font-weight-bold">
        Thiết lập phiên bản {{ specs.name }}
      </v-toolbar-title>

      <v-spacer></v-spacer>

      <v-btn class="mr-2" color="primary" depressed @click="onUpdateSpecs">
        Lưu
      </v-btn>

      <v-btn icon @click="toggle">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>

    <v-container class="grey lighten-3 pa-5 black--text" fluid>
      <div class="mb-4 flex flex-col">
        <div class="flex flex-wrap">
          <v-btn
            v-for="tab in ARRAY_LIST_MODEL_SITES"
            :key="tab.id"
            :color="currentTab === tab.id ? 'primary' : 'white'"
            class="mr-2"
            depressed
            small
            @click="currentTab = tab.id"
          >
            {{ tab.text }}
          </v-btn>
        </div>
      </div>

      <v-card class="px-5 py-4" flat>
        <div class="font-weight-bold mb-2">Thông tin SEO</div>

        <div>
          <form-seo-data-item
            v-model="seoData[currentTab].defaultSeo"
          ></form-seo-data-item>
        </div>

        <div>
          <v-checkbox
            v-model="seoData[currentTab].isSchedule"
            class="font-weight-bold"
            label="Hẹn giờ"
          ></v-checkbox>

          <div v-if="seoData[currentTab].isSchedule">
            <form-seo-data-item
              v-model="seoData[currentTab].scheduleSeo"
              schedule
            ></form-seo-data-item>
          </div>
        </div>
      </v-card>

      <v-card class="mt-5 px-5 py-4" flat>
        <div class="font-weight-bold mb-2">Bài viết mô tả</div>

        <ThinkTiptap
          v-model="content[currentTab]"
          :config="config"
          class="tp-tiptap"
        ></ThinkTiptap>
      </v-card>
    </v-container>
  </v-dialog>
</template>

<script>
import { defineComponent, getCurrentInstance, ref } from "vue";

import ProductService from "@/modules/Goods/service/product.service";
import { ARRAY_LIST_MODEL_SITES } from "@/core/constant";
import FormSeoDataItem from "@/core/components/form/FormSeoData/FormSeoDataItem.vue";
import { createVuexHelpers } from "@/core/composables";
import {
  deserialize,
  serialize
} from "@/core/components/form/FormSeoData/utils";
import { useVisible } from "@thinkvn/composables/lib/useVisible";
import { cloneDeep } from "@vt7/utils";

export default defineComponent({
  components: { FormSeoDataItem },

  props: {
    specs: {
      type: Object,
      required: true
    }
  },

  setup(props) {
    const instance = getCurrentInstance();
    const { visible, toggle } = useVisible();
    const { useActions, useGetters } = createVuexHelpers();

    const { product } = useGetters("PRODUCT", ["product"]);
    const { getProductById } = useActions("PRODUCT", ["getProductById"]);

    const currentTab = ref("THINKPRO");
    const seoData = ref(serialize(props.specs?.seo_data, currentTab.value));
    const content = ref(cloneDeep(props.specs.content));

    const onUpdateSpecs = async () => {
      try {
        await ProductService.updateProductSpecs({
          ...props.specs,
          specsable_id: product.value.id,
          content: content.value,
          seo_data: deserialize(seoData.value)
        });

        await getProductById(product.value.id);

        instance.proxy.$toast.show({
          name: "toast-action-alert",
          payload: {
            message: "Cập nhật phiên bản thành công"
          }
        });
      } catch (e) {
        console.log("onUpdateSpecs", e);

        instance.proxy.$toast.show({
          name: "toast-action-alert",
          payload: {
            message: "Cập nhật phiên bản không thành công"
          }
        });
      }
    };

    return {
      visible,
      toggle,
      currentTab,
      seoData,
      content,
      onUpdateSpecs
    };
  },

  data() {
    return {
      ARRAY_LIST_MODEL_SITES,
      config: {
        baseURL: process.env.VUE_APP_THINKPRO_FRONT_STORE,
        imageUploadFolder: "core/site-configs",
        sites: ["THINKPRO", "GOODSPACE"]
      }
    };
  }
});
</script>
