<template>
  <div>
    <v-btn color="primary" icon @click="visible = true">
      <v-icon>mdi-fullscreen</v-icon>
    </v-btn>

    <v-dialog v-model="visible" width="690px">
      <v-card class="pa-4">
        <v-btn class="button-close" icon small @click="visible = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>

        <card-gallery-expand></card-gallery-expand>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import CardGalleryExpand from "./CardGalleryExpand";
export default {
  components: {
    CardGalleryExpand
  },
  data() {
    return {
      visible: false
    };
  }
};
</script>

<style lang="scss" scoped>
.button-close {
  position: absolute;
  right: 12px;
  top: 12px;
}
</style>
