<template>
  <div class="d-flex align-center">
    <v-textarea
      ref="textarea"
      v-model="internalSpecs.name"
      auto-grow
      class="text-body-1"
      hide-details
      no-resize
      outlined
      placeholder="VD: I5 - 128GB"
      rows="1"
    >
      <template v-if="isChanged" #append>
        <v-btn
          icon
          style="position: absolute;right: 12px;top: 50%;transform: translateY(-50%)"
          @click="onUpdateSpecs"
        >
          <v-icon>mdi-check</v-icon>
        </v-btn>
      </template>
    </v-textarea>

    <v-btn icon @click="onDeleteSpecs">
      <v-icon>mdi-delete</v-icon>
    </v-btn>
  </div>
</template>

<script>
import { defineComponent, getCurrentInstance } from "vue";
import { useResetRef } from "@vt7/composables";
import { cloneDeep } from "@vt7/utils";

import { createVuexHelpers } from "@/core/composables";
import ProductService from "@/modules/Goods/service/product.service";

import ModalDetailSpecs from "./ModalDetailSpecs.vue";

export default defineComponent({
  components: { ModalDetailSpecs },

  props: {
    specs: {
      type: Object,
      required: true
    }
  },

  setup(props, { emit }) {
    const instance = getCurrentInstance();
    const { state: internalSpecs, isChanged } = useResetRef(() => props.specs);

    const { useGetters, useActions } = createVuexHelpers();
    const { product } = useGetters("PRODUCT", ["product"]);
    const { getProductById } = useActions("PRODUCT", ["getProductById"]);

    const onUpdateSpecs = async () => {
      try {
        await ProductService.updateProductSpecs({
          ...internalSpecs.value,
          specsable_id: product.value.id
        });

        emit("update:specs", cloneDeep(internalSpecs.value));

        instance.proxy.$toast.show({
          name: "toast-action-alert",
          payload: {
            message: "Cập nhật phiên bản thành công"
          }
        });
      } catch (e) {
        console.log("onUpdateSpecs", e);

        instance.proxy.$toast.show({
          name: "toast-action-alert",
          payload: {
            message: e.response.data
          }
        });
      }
    };

    const onDeleteSpecs = async () => {
      instance.proxy.$modal.show({
        name: "modal-alert",
        payload: {
          title: `<span>Xóa cấu hình <strong>${internalSpecs.value.name}</strong></span>`,
          message:
            "Hành động này sẽ không thể hoàn tác. Bạn có chắc chắn muốn tiếp tục?",
          confirmBtn: {
            color: "red accent-2",
            text: "Xóa",
            onClickHandler: async () => {
              try {
                await ProductService.deleteSpecs(internalSpecs.value.id);

                await getProductById(product.value.id);

                instance.proxy.$toast.show({
                  name: "toast-action-alert",
                  payload: {
                    message: "Xoá phiên bản thành công"
                  }
                });
              } catch (e) {
                instance.proxy.$toast.show({
                  name: "toast-action-alert",
                  payload: {
                    message: e.response.data
                  }
                });
              }
            }
          }
        }
      });
    };

    return {
      product,
      internalSpecs,
      isChanged,
      onUpdateSpecs,
      onDeleteSpecs
    };
  },

  mounted() {
    setTimeout(() => {
      this.$refs.textarea.calculateInputHeight();
    }, 1000);
  }
});
</script>
