<template>
  <tp-modal max-width="560px" name="modal-form-color" width="560px">
    <v-card slot-scope="props">
      <v-card-title class="font-weight-bold">
        {{ color.id ? "Sửa màu" : "Thêm màu" }}
      </v-card-title>

      <v-form ref="form">
        <div class="text-body-1 px-6">
          <div class="mt-2">
            <div class="font-weight-bold mb-2">Tên màu</div>

            <tp-text-field
              v-model="color.name"
              placeholder="Nhập tên màu"
              validate="required"
            ></tp-text-field>
          </div>

          <v-row class="mt-5">
            <v-col class="py-0" cols="7">
              <div class="font-weight-bold mb-2">Chọn mã màu</div>
              <v-color-picker
                v-model="selectedColor"
                dot-size="25"
                mode="hexa"
                swatches-max-height="200"
              ></v-color-picker>
            </v-col>
            <v-col class="py-0 col-color-preview" cols="5">
              <div class="font-weight-bold mb-2">Xem trước</div>
              <v-card class="grey lighten-5 pa-2 rounded" flat>
                <div class="white rounded-lg">
                  <v-img
                    :src="productImage"
                    class="col-color-preview__img rounded-lg"
                  ></v-img>
                  <div class="px-3 pb-4">
                    <div class="font-weight-black">HP OMEN 15 2020</div>
                    <div>
                      <span class="text-body-2 grey--text font-weight-bold mr-1"
                        >Từ</span
                      ><span class="font-weight-black" style="color: #FE3464">{{
                        19990000 | formatCurrency
                      }}</span>
                    </div>
                    <div class="d-flex align-center">
                      <div class="mr-1 text-body-2 grey--text font-weight-bold">
                        Màu
                      </div>
                      <div
                        :style="{ backgroundColor: color.code }"
                        class="color-preview--small"
                      ></div>
                    </div>
                  </div>
                </div>
              </v-card>
            </v-col>
          </v-row>

          <div class="mt-5">
            <div class="font-weight-bold mb-2">Thuộc nhóm màu (filter)</div>
            <v-select
              v-model="color.color_group_id"
              :items="colorGroups"
              :menu-props="{ nudgeTop: '-40px' }"
              background-color="white"
              class="text-body-1"
              dense
              filled
              item-text="name"
              item-value="id"
              outlined
              placeholder="Chọn nhóm màu"
              :rules="[value => !!value || 'Không được bỏ trống']"
            >
              <template v-slot:item="data">
                <v-list-item-title class="d-flex align-center">
                  <div class="d-flex align-center py-1">
                    <div
                      :style="{
                        backgroundColor: data.item.code
                      }"
                      class="color-preview d-flex align-center justify-center mr-2"
                    ></div>
                    <div>{{ data.item.name }}</div>
                  </div>
                </v-list-item-title>
              </template>
            </v-select>
          </div>
        </div>
      </v-form>

      <v-card-actions class="px-5 pb-4 pt-0">
        <v-spacer></v-spacer>
        <v-btn color="grey darken-1" text @click="closeModal">
          Bỏ qua
        </v-btn>

        <v-btn
          v-if="color.id"
          :loading="colorStatusRequest.value === 'loading-updateColor'"
          color="primary"
          text
          @click="
            colorStatusRequest.value === 'loading-updateColor'
              ? null
              : updateColor(props.payload.onSuccessfulUpdate || undefined)
          "
        >
          Lưu
        </v-btn>

        <v-btn
          v-else
          :loading="colorStatusRequest.value === 'loading-createColor'"
          color="primary"
          text
          @click="
            colorStatusRequest.value === 'loading-createColor'
              ? null
              : createColor(props.payload.onSuccessfulCreate || undefined)
          "
        >
          Thêm
        </v-btn>
      </v-card-actions>
    </v-card>
  </tp-modal>
</template>

<script>
export default {
  data() {
    return {
      productImage: require("@/core/assets/images/product-image.jpg")
    };
  },

  computed: {
    color() {
      return this.$store.getters["COLOR/color"];
    },
    colorGroups() {
      return this.$store.getters["COLOR/colorGroups"];
    },
    colorStatusRequest() {
      return this.$store.getters["COLOR/statusRequest"];
    },
    selectedColor: {
      get() {
        return this.color.code;
      },
      set(val) {
        if (typeof val === "string") {
          this.$set(this.color, "code", val);
        } else {
          this.$set(this.color, "code", val.hex);
        }
      }
    }
  },

  created() {
    this.$store.dispatch("COLOR/getColorGroups");
  },

  methods: {
    closeModal() {
      this.$modal.hide({ name: "modal-form-color" });
      this.$refs.form.resetValidation();
    },

    async createColor(onSuccessfulCreate) {
      const isValid = this.$refs.form.validate();

      if (!isValid) return;

      await this.$store.dispatch("COLOR/createColor", { ...this.color });

      if (this.colorStatusRequest.value === "success-createColor") {
        this.$toast.show({
          name: "toast-action-alert",
          payload: {
            message: "Thêm mới màu thành công"
          }
        });

        if (typeof onSuccessfulCreate === "function") {
          onSuccessfulCreate(this.color);
        }

        this.closeModal();
      }
    },

    async updateColor(onSuccessfulUpdate) {
      const isValid = this.$refs.form.validate();

      if (!isValid) return;

      await this.$store.dispatch("COLOR/updateColor", { ...this.color });

      if (this.colorStatusRequest.value === "success-updateColor") {
        this.$toast.show({
          name: "toast-action-alert",
          payload: {
            message: "Cập nhật màu thành công"
          }
        });

        if (typeof onSuccessfulUpdate === "function") {
          onSuccessfulUpdate(this.color);
        }

        this.closeModal();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.color-preview {
  border: 1px solid #e5e5e5;
  height: 24px;
  width: 24px;
  &--small {
    border-radius: 2px;
    height: 12px;
    width: 12px;
  }
}

.col-color-preview {
  &__img {
    width: 100%;
  }
}
</style>
