<template>
  <tp-modal name="model-insert-video" width="640px">
    <v-card>
      <v-card-title>
        <div class="font-weight-bold">
          Insert Video Youtube
        </div>

        <v-spacer></v-spacer>

        <v-btn color="primary" dark depressed @click="handleInsertVideo">
          Lưu
        </v-btn>

        <v-btn icon @click="closeModal()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-container class="grey lighten-3 pa-5" fluid>
        <v-card class="pa-5" flat>
          <v-text-field
            v-model="internalUrl"
            class="text-body-1"
            dense
            hide-details="auto"
            outlined
            placeholder="Nhập link youtube"
          ></v-text-field>

          <div v-if="getYoutubeId(internalUrl)" class="mt-5">
            <iframe
              :src="getYoutubeEmbed(internalUrl)"
              width="100%"
              height="500px"
              allowfullscreen
            ></iframe>
          </div>
        </v-card>
      </v-container>
    </v-card>
  </tp-modal>
</template>

<script>
import { getYoutubeEmbed, getYoutubeId } from "@vt7/utils";

export default {
  props: {
    url: String
  },

  data() {
    return {
      internalUrl: ""
    };
  },

  methods: {
    closeModal() {
      this.$modal.hide({ name: "model-insert-video" });
      this.internalUrl = this.url || "";
    },

    getYoutubeId(url) {
      return getYoutubeId(url);
    },

    getYoutubeEmbed(url) {
      return getYoutubeEmbed(url);
    },

    handleInsertVideo() {
      this.$emit("onInserted", this.internalUrl);
      this.closeModal();
    }
  },

  watch: {
    url: {
      handler: function(newValue) {
        this.internalUrl = newValue || "";
      },
      immediate: true
    }
  }
};
</script>
