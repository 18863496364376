<template>
  <v-autocomplete
    v-model="internalValue"
    :items="['add', ...colors]"
    dense
    item-text="name"
    item-value="id"
    no-data-text="Không có dữ liệu"
    outlined
    placeholder="Chọn màu sắc"
    single-line
    v-bind="$attrs"
  >
    <template v-slot:item="data">
      <v-btn
        v-if="data.item === 'add'"
        block
        class="grey lighten-4"
        plain
        small
        text
        @click.stop="openModalFormColor"
      >
        <v-icon left>mdi-plus</v-icon>
        Thêm màu
      </v-btn>

      <v-list-item-title v-else class="d-flex align-center">
        <div class="d-flex align-center py-1">
          <div
            :style="{
              backgroundColor: data.item.code || 'white'
            }"
            class="d-flex align-center justify-center mr-3 rounded"
            style="height: 32px; width: 32px;"
          >
            <span>{{ !data.item.code ? "?" : null }}</span>
          </div>

          <div>
            <div>{{ data.item.name }}</div>
            <div class="text-body-2 grey--text text--darken-2">
              {{ data.item.code || "Chưa có mã màu" }}
            </div>
          </div>
        </div>

        <v-spacer></v-spacer>

        <v-btn icon small @click.stop="openModalFormColor(data.item)">
          <v-icon color="grey" size="16px">mdi-pencil</v-icon>
        </v-btn>
      </v-list-item-title>
    </template>
  </v-autocomplete>
</template>

<script>
import { defineComponent, getCurrentInstance } from "vue";
import { useVModel } from "@vueuse/core";

import { createVuexHelpers } from "@/core/composables";

export default defineComponent({
  props: {
    value: {
      type: Number,
      default: null
    }
  },

  setup(props, { emit }) {
    const instance = getCurrentInstance();
    const { useGetters, useActions } = createVuexHelpers();
    const { colors } = useGetters("COLOR", ["colors"]);
    const { setColor, resetColor } = useActions("COLOR", [
      "setColor",
      "resetColor"
    ]);

    const internalValue = useVModel(props, "value", emit, {
      eventName: "input"
    });

    const openModalFormColor = item => {
      if (item) {
        setColor(item);
      } else {
        resetColor();
      }

      instance.proxy.$modal.show({
        name: "modal-form-color",
        payload: {
          onSuccessfulCreate: color => {
            internalValue.value = color.id;
          },
          onSuccessfulUpdate: color => {
            internalValue.value = color.id;
          }
        }
      });
    };

    return {
      internalValue,
      colors,
      openModalFormColor
    };
  }
});
</script>
