<template>
  <v-card flat>
    <v-card-title class="pa-5">
      <h5 class="font-weight-bold">Thông tin chung</h5>
    </v-card-title>

    <div class="px-5">
      <div>
        <div class="font-weight-bold mb-2">Tên sản phẩm</div>

        <v-text-field
          v-model="product.name"
          background-color="white"
          class="text-body-1"
          dense
          filled
          outlined
          placeholder="Nhập tên sản phẩm"
          single-line
        ></v-text-field>
      </div>

      <div class="d-flex">
        <div class="mr-6">
          <div class="font-weight-bold mb-2">Ảnh đại diện</div>

          <div class="card-general-image">
            <tp-input-image
              :height="256 * 2"
              :maxFileSize="300 * 1024"
              :src.sync="product.thumbnail"
              :width="256 * 2"
              allowCompress
              allowFileDimensionValidation
              allowFileSize
              allowFileTypeValidation
              class="flex-grow-1"
              forceType="png"
              required
            ></tp-input-image>
          </div>
        </div>

        <div class="flex-1">
          <div class="font-weight-bold mb-2">Mô tả sản phẩm</div>

          <v-textarea
            v-model="product.description"
            background-color="white"
            class="text-body-1"
            dense
            filled
            outlined
            placeholder="Nhập mô tả sản phẩm"
          ></v-textarea>
        </div>
      </div>

      <div class="d-flex gap-5">
        <div class="w-full">
          <div class="font-weight-bold mb-2">Mã sản phẩm</div>

          <v-text-field
            v-model="product.code"
            background-color="white"
            class="text-body-1"
            dense
            filled
            full-width
            outlined
            placeholder="Nhập mã sản phẩm"
            single-line
          ></v-text-field>
        </div>

        <div class="w-full">
          <div class="font-weight-bold mb-2">Category</div>

          <treeselect
            v-model="product.category_id"
            :disable-branch-nodes="true"
            :options="categoryTrees"
            class="custom-treeselect"
            height="100"
            placeholder="Chọn Category"
            search-nested
            @select="handleSelectCategory"
          ></treeselect>
        </div>

        <div class="w-full">
          <div class="font-weight-bold mb-2">Brand</div>

          <treeselect
            v-model="product.brand_id"
            :options="BrandTrees"
            class="custom-treeselect"
            height="100"
            noOptionsText="Không có kết quả"
            placeholder="Chọn Brand"
          ></treeselect>
        </div>
      </div>
    </div>
  </v-card>
</template>

<script>
import { arrayToTree } from "performant-array-to-tree";
import { debounce, removeEmptyChildrenProperty } from "@/core/utils";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";

export default {
  components: { Treeselect: Treeselect },

  data() {
    return {
      formDataThumbnail: null
    };
  },

  computed: {
    brands() {
      return this.$store.getters["BRAND/allBrands"];
    },

    brandsFlat() {
      return this.$store.getters["BRAND/brandsFlat"];
    },

    BrandTrees() {
      const brandsTree = arrayToTree(this.brandsFlat, {
        dataField: null,
        parentId: "parent_id"
      });

      return removeEmptyChildrenProperty(brandsTree, "children");
    },

    product() {
      return this.$store.getters["PRODUCT/product"];
    },

    productTypes() {
      return this.$store.getters["PRODUCT_TYPE/allProductTypes"];
    },

    categoryTrees() {
      return this.$store.getters["PRODUCT_TYPE/categoryTrees"];
    },

    tag() {
      return this.$store.getters["TAG/tag"];
    },

    tags() {
      return this.$store.getters["TAG/allTags"];
    }
  },

  async created() {
    if (this.brands.length === 0) {
      await this.$store.dispatch("BRAND/getAllBrands");
    }

    if (this.brandsFlat.length === 0) {
      await this.$store.dispatch("BRAND/getBrandsFlat");
    }

    if (this.tags.length === 0) {
      await this.$store.dispatch("TAG/getAllTags");
    }

    await this.$store.dispatch("PRODUCT_TYPE/getAllProductTypes");

    this.debounceGetPosts = debounce(this.getPosts, 700);
  },

  methods: {
    handleSelectCategory() {
      this.product.brand_id = null;
    },

    updateFormDataThumbnail(val) {
      this.$emit("updateFormDataThumbnail", val);
    }
  }
};
</script>

<style scoped>
.w-full {
  width: 100%;
}

.gap-5 {
  gap: 20px !important;
}

::v-deep .custom-treeselect .vue-treeselect__control {
  height: 40px;
  border-color: #9e9e9e;
}

.card-general-image {
  width: 136px;
}
</style>
