<template>
  <v-card class="mt-5 px-5 py-4" flat>
    <v-card-title class="mb-4 pa-0">
      <h5 class="font-weight-bold">Ảnh và Video</h5>
    </v-card-title>

    <button-expand-card-gallery
      class="expand-button"
    ></button-expand-card-gallery>

    <card-gallery-item></card-gallery-item>
  </v-card>
</template>

<script>
import ButtonExpandCardGallery from "./ButtonExpandCardGallery";
import CardGalleryItem from "./CardGalleryItem";

export default {
  components: {
    ButtonExpandCardGallery,
    CardGalleryItem
  }
};
</script>

<style lang="scss" scoped>
.expand-button {
  position: absolute;
  right: 0;
  top: 0;
}
</style>
