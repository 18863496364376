<template>
  <div>
    <div class="font-weight-bold mb-2">{{ title }}</div>
    <div class="d-flex flex-wrap align-center">
      <div v-for="(item, index) in internalValue" :key="index">
        <v-checkbox
          v-model="item.available"
          class="mt-0"
          dense
          :label="LIST_MODEL_SITES[item.site].text"
          :name="`${modelType}-${modelId}-${index}`"
          @change="onChangeValue"
        ></v-checkbox>
      </div>
    </div>
  </div>
</template>

<script>
import { LIST_MODEL_SITES } from "@/core/constant";

export default {
  props: {
    modelId: {
      type: Number,
      default: null
    },
    modelType: {
      type: String,
      required: true
    },
    title: {
      type: String,
      default: "Hiển thị trên website"
    },
    value: {
      type: Array,
      required: true
    }
  },

  data() {
    return {
      LIST_MODEL_SITES
    };
  },

  computed: {
    internalValue() {
      const listModelSites = Object.values(LIST_MODEL_SITES);

      if (this.value.length === listModelSites.length) return this.value;

      return listModelSites.map((item, index) => {
        return {
          available: index === 0,
          site: item.id,
          model_id: this.modelId,
          model_type: this.modelType
        };
      });
    }
  },

  methods: {
    onChangeValue() {
      this.$emit("input", this.internalValue);
    }
  }
};
</script>

<style>
.d-flex.flex-wrap.align-center {
  column-gap: 24px;
  row-gap: 8px;
}
</style>
