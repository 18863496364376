<template>
  <div class="d-flex align-center">
    <v-textarea
      v-model="specs"
      auto-grow
      class="text-body-1"
      hide-details
      no-resize
      outlined
      placeholder="VD: I5 - 128GB"
      rows="1"
    ></v-textarea>

    <v-btn
      class="ml-3"
      color="success"
      dark
      depressed
      @click="handleCreateNewSpecs"
    >
      Thêm
    </v-btn>
  </div>
</template>

<script>
import { defineComponent, ref } from "vue";
import { createVuexHelpers } from "@/core/composables";
import ProductService from "@/modules/Goods/service/product.service";

export default defineComponent({
  setup() {
    const { useGetters, useActions } = createVuexHelpers();
    const { product } = useGetters("PRODUCT", ["product"]);
    const { getProductById } = useActions("PRODUCT", ["getProductById"]);

    const specs = ref("");

    const handleCreateNewSpecs = async () => {
      await ProductService.addProductSpecs({
        specsable_id: product.value.id,
        name: specs.value
      });

      // refetch api to update value
      await getProductById(product.value.id);

      specs.value = "";
    };

    return {
      specs,
      handleCreateNewSpecs
    };
  }
});
</script>
