<template>
  <v-data-table
    :headers="headers"
    :items="skus"
    :items-per-page="10"
    class="rounded-0 pa-0"
    loading-text="Đang tải dữ liệu"
    no-data-text="Không có dữ liệu"
  >
    <template v-slot:body="{ items }">
      <tbody
        :key="statusRequest.value === 'success-getProductOptionsByProductId'"
      >
        <modal-form-color></modal-form-color>

        <table-record-sku
          v-for="(item, index) in items"
          :key="item.id"
          :sku.sync="items[index]"
        ></table-record-sku>

        <table-record-sku :sku="createSkuState">
          <template #action="{ state, reset }">
            <v-btn
              class="ml-3"
              color="success"
              dark
              depressed
              @click="onCreateSku(state, reset)"
            >
              Thêm
            </v-btn>
          </template>
        </table-record-sku>
      </tbody>
    </template>
  </v-data-table>
</template>

<script>
import { computed, defineComponent, getCurrentInstance } from "vue";

import { createVuexHelpers } from "@/core/composables";
import ProductOptionService from "@/modules/Goods/service/product-option.service";
import TableRecordSku from "./TableRecordSku.vue";
import ModalFormColor from "./ModalFormColor.vue";

export default defineComponent({
  components: { ModalFormColor, TableRecordSku },

  setup() {
    const instance = getCurrentInstance();
    const { useGetters, useActions } = createVuexHelpers();
    const { product } = useGetters("PRODUCT", ["product"]);
    const {
      productOptionsOfProduct: skus,
      statusRequest
    } = useGetters("PRODUCT_OPTION", [
      "productOptionsOfProduct",
      "statusRequest"
    ]);
    const { getProductOptionsByProductId } = useActions("PRODUCT_OPTION", [
      "getProductOptionsByProductId"
    ]);

    const onCreateSku = async (state, reset) => {
      try {
        await ProductOptionService.createProductOption({
          ...state,
          color_id: state.color.id
        });

        reset();
        getProductOptionsByProductId(product.value.id);

        instance.proxy.$toast.show({
          name: "toast-action-alert",
          payload: {
            message: "Thêm mới thành công"
          }
        });
      } catch (e) {
        console.log({ ...e });

        instance.proxy.$toast.show({
          name: "toast-action-alert",
          payload: {
            message: e?.response?.data || "Thêm mới không thành công"
          }
        });
      }
    };

    return {
      skus,
      statusRequest,
      onCreateSku,
      createSkuState: computed(() => ({
        product_id: product.value.id,
        SKU: "",
        color: { id: null },
        name: "",
        nguon_hang: 0,
        none_price: 0,
        price: "",
        specs: "",
        specs_id: 0,
        ttkd: 1,
        tinh_trang: null
      }))
    };
  },

  data() {
    return {
      headers: [
        {
          text: "Mã SKU",
          sortable: false,
          value: "SKU",
          width: 250
        },
        {
          text: "Phiên bản",
          sortable: false,
          value: "specs"
        },
        {
          text: "Màu sắc",
          sortable: false,
          value: "color"
        },
        {
          text: "Nguồn hàng",
          sortable: false,
          value: "nguon_hang",
          width: 150
        },
        {
          text: "Giá",
          sortable: false,
          value: "price",
          width: 140
        },
        {
          text: "Tình trạng máy",
          sortable: false,
          value: "tinh_trang",
          width: 140
        },
        {
          text: "Ngừng KD",
          sortable: false,
          value: "ttkd",
          width: 70
        },
        {
          text: "Trạng thái",
          sortable: false,
          value: "status",
          align: "center",
          width: 90
        },
        {
          text: "",
          sortable: false,
          value: "id"
        }
      ]
    };
  }
});
</script>
