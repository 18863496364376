<template>
  <v-card class="mt-5 px-5 py-4" flat>
    <v-card-title class="pa-0 pb-1">
      <h5 class="font-weight-bold">Phân loại sản phẩm</h5>
    </v-card-title>

    <template v-if="product.id">
      <div class="mt-2">
        <span class="font-weight-bold">Các phiên bản</span>

        <v-row class="mt-3">
          <v-col
            v-for="(spec, index) in product.specs"
            :key="spec.id"
            class="d-flex align-center"
            cols="12"
            md="4"
          >
            <form-item-detail-specs
              :specs.sync="product.specs[index]"
              class="w-full"
            ></form-item-detail-specs>
          </v-col>

          <v-col cols="12" md="4">
            <form-item-create-specs class="w-full"></form-item-create-specs>
          </v-col>
        </v-row>
      </div>

      <div class="mt-4">
        <span class="font-weight-bold">Danh sách SKU</span>

        <table-sku-listing></table-sku-listing>
      </div>
    </template>

    <template v-else>
      <div class="flex align-center justify-center" style="height: 200px">
        <span>Vui lòng lưu sản phẩm trước để thiết lập phân loại.</span>
      </div>
    </template>
  </v-card>
</template>

<script>
import { defineComponent } from "vue";
import { createVuexHelpers } from "@/core/composables";

import FormItemCreateSpecs from ".//FormItemCreateSpecs.vue";
import FormItemDetailSpecs from "./FormItemDetailSpecs.vue";
import TableSkuListing from "./TableSkuListing.vue";

export default defineComponent({
  components: { TableSkuListing, FormItemDetailSpecs, FormItemCreateSpecs },

  setup() {
    const { useGetters } = createVuexHelpers();
    const { product } = useGetters("PRODUCT", ["product"]);

    return { product };
  }
});
</script>
