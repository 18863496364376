<template>
  <div>
    <v-row class="my-0">
      <v-col class="py-0" cols="12">
        <div class="gallery pb-3">
          <div>
            <div class="font-weight-bold mb-2">Video</div>

            <v-hover v-slot:default="{ hover }">
              <div class="video-wrapper" @click="handleOpenModalInsertVideo">
                <div class="gallery-add rounded">
                  <v-icon
                    v-if="!product.youtube_url"
                    class="grey--text text--lighten-1"
                    large
                    >mdi-plus
                  </v-icon>

                  <v-img
                    v-else
                    :src="getYoutubeThumbnail(product.youtube_url)"
                    alt="youtube-thumbnail"
                  ></v-img>

                  <v-fade-transition>
                    <v-row
                      v-if="hover && product.youtube_url"
                      align="center"
                      class="fill-height grey darken-4 ma-0 rounded"
                      justify="center"
                      style="opacity: 0.65; position: absolute;width: 100%;"
                    >
                      <v-btn color="white" icon>
                        <v-icon>mdi-eye-outline</v-icon>
                      </v-btn>

                      <v-btn color="white" icon @click="removeVideo">
                        <v-icon>mdi-delete-outline</v-icon>
                      </v-btn>
                    </v-row>
                  </v-fade-transition>
                </div>
              </div>
            </v-hover>
          </div>

          <div class="ml-4">
            <div class="font-weight-bold mb-2">Bộ ảnh</div>

            <div class="ml-1 ">
              <draggable
                v-model="product.images"
                class="d-flex flex-wrap gap-3"
                draggable=".item"
                v-bind="dragOptions"
              >
                <div slot="header">
                  <t-upload-image
                    v-slot="{
                      on,
                      isCompressing
                    }"
                    :max-file-size="700 * 1024"
                    :min-size="{ width: 2000, height: 1125 }"
                    :short-pixel-api-keys="apiKeys"
                    :target-size="{ width: 2000, height: 2000 }"
                    allow-compare-image
                    allow-compress
                    allow-file-dimension-validation
                    allow-file-size
                    allow-file-type-validation
                    allow-resize-file
                    required
                    @change="onChangeImage"
                  >
                    <fieldset
                      :disabled="isCompressing"
                      class="gallery-add rounded"
                      v-on="on"
                    >
                      <v-btn :loading="isCompressing" icon>
                        <v-icon class="grey--text text--lighten-1" large>
                          mdi-plus
                        </v-icon>
                      </v-btn>
                    </fieldset>
                  </t-upload-image>
                </div>

                <v-hover
                  v-for="(item, index) in product.images"
                  :key="index"
                  v-slot:default="{ hover }"
                  class="item cursor-move"
                >
                  <!-- @mousedown="isDrag = true"
                    @mousemove="isDrag = true"
                    @mouseup="isDrag = false" -->
                  <div class="gallery-item">
                    <v-img
                      :aspect-ratio="1"
                      :src="
                        item && item.slice(0, 5) !== 'blob:' ? item : undefined
                      "
                      class="grey lighten-3 rounded tp-boder-thin"
                      height="120px"
                      width="120px"
                    >
                      <v-fade-transition>
                        <v-row
                          v-if="hover"
                          align="center"
                          class="fill-height grey darken-4 ma-0"
                          justify="center"
                          style="opacity: 0.65"
                        >
                          <v-btn color="white" icon @click="previewImage(item)">
                            <v-icon>mdi-eye-outline</v-icon>
                          </v-btn>

                          <v-btn color="white" icon @click="removeImage(item)">
                            <v-icon>mdi-delete-outline</v-icon>
                          </v-btn>
                        </v-row>
                      </v-fade-transition>
                      <template v-slot:placeholder>
                        <v-row
                          align="center"
                          class="fill-height ma-0"
                          justify="center"
                        >
                          <v-progress-circular
                            color="grey lighten-5"
                            indeterminate
                          ></v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>
                  </div>
                </v-hover>
              </draggable>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>

    <modal-preview-image :url="selectedImage"></modal-preview-image>

    <modal-insert-video
      :url="product.youtube_url"
      @onInserted="handleInsertedVideo"
    ></modal-insert-video>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import { toFormData } from "@vt7/utils";
import TUploadImage from "@vt7/upload-image/lib/t-upload-image.vue";
import { showModalAlertError } from "@/core/composables";
import { getYoutubeThumbnail } from "@/core/utils";
import ModalPreviewImage from "./ModalPreviewImage";
import ModalInsertVideo from "./ModalInsertVideo";

export default {
  components: {
    draggable,
    ModalPreviewImage,
    ModalInsertVideo,
    TUploadImage
  },
  data() {
    return {
      apiKeys: process.env.VUE_APP_COMPRESS_API_KEYS.split(","),
      file: null,
      files: null,
      selectedImage: "",
      isDrag: false
    };
  },

  computed: {
    categories() {
      return this.$store.getters["PRODUCT_TYPE/categories"];
    },

    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost"
      };
    },

    product() {
      return this.$store.getters["PRODUCT/product"];
    },

    productStatusRequest() {
      return this.$store.getters["PRODUCT/statusRequest"];
    },

    productModelSiteConfigs() {
      return this.$store.getters["PRODUCT/productModelSiteConfigs"];
    },

    selectedSites() {
      return this.productModelSiteConfigs
        .filter(item => item.available)
        .map(item => item.site);
    },

    tempThumbnailExpiredTime: {
      get() {
        return this.getTime(this.product.temporary_thumbnail.expired_time);
      },
      set(val) {
        this.product.temporary_thumbnail.expired_time = this.setTime(
          this.product.temporary_thumbnail.expired_time,
          val
        ).toISOString();
      }
    },

    uploadedImage() {
      return this.$store.getters["PRODUCT/uploadedThumbnail"];
    }
  },

  methods: {
    clearTempThumbnail() {
      this.product.temporary_thumbnail.expired_time = new Date().toISOString();
      this.product.temporary_thumbnail.path = null;
    },

    handleInsertedVideo(value) {
      this.product.youtube_url = value;
    },

    getSitesByCategoryId(categoryId) {
      return this.categories.find(item => item.id === categoryId).sites;
    },

    getTime(date) {
      if (!date || date === "") return "00:00";

      const dateTime = new Date(date),
        hours = dateTime
          .getHours()
          .toString()
          .padStart(2, "0"),
        mins = dateTime
          .getMinutes()
          .toString()
          .padStart(2, "0");

      return `${hours}:${mins}`;
    },

    getYoutubeThumbnail(url) {
      return getYoutubeThumbnail(url);
    },

    handleOpenModalInsertVideo() {
      this.$modal.show({ name: "model-insert-video" });
    },

    previewImage(url) {
      this.selectedImage = url;
      this.$modal.show({ name: "modal-preview-image" });
    },

    removeImage(link) {
      this.product.images = this.product.images.filter(item => {
        return item !== link;
      });
    },

    removeVideo(event) {
      event.stopPropagation();

      this.product.youtube_url = "";
    },

    async onChangeImage(file) {
      await this.onUploadImage(file);
    },

    setTime(date, time) {
      let dateTime = new Date(date);
      const [hours, mins] = time.split(":");

      dateTime.setHours(parseInt(hours));
      dateTime.setMinutes(parseInt(mins));

      return dateTime;
    },

    async uploadImage(formData) {
      if (!formData) return;

      const payload = {
        formData: formData,
        name: this.product.name,
        sites: this.selectedSites.length
          ? this.selectedSites
          : this.getSitesByCategoryId(this.product.category_id)
      };

      await this.$store.dispatch("PRODUCT/uploadThumbnail", payload);
    },

    async onUploadImage(file) {
      await this.uploadImage(
        toFormData({
          file: file
        })
      );

      // Set uploaded image
      if (this.productStatusRequest.value === "success-uploadThumbnail") {
        this.product.images = this.product.images.concat(this.uploadedImage);
      } else if (this.productStatusRequest.value === "error-uploadThumbnail") {
        showModalAlertError(this, {
          title: "Lỗi tải lên hình ảnh",
          message: this.productStatusRequest.message
        });

        return;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.cursor-move {
  cursor: move;
}

.ghost {
  opacity: 0.5;
}

.gap-3 {
  gap: 12px;
}

.expand-button-wrapper {
  position: absolute;
  right: 4px;
  top: 4px;
}

.gallery {
  display: flex;
  width: 100%;

  &-add {
    align-items: center;
    border: 2px dashed #e5e5e5;
    cursor: pointer;
    display: flex;
    justify-content: center;
    height: 120px !important;
    transition: all 0.4s ease;
    width: 120px !important;

    &:hover {
      background-color: #f9f9f9;
      border-color: #e0e0e0;
    }
  }

  &-item {
    display: flex;

    .v-image {
      border: 1px solid #e5e5e5;
    }
  }
}

.preview-gallery {
  display: flex;
}

.video-wrapper {
  position: relative;
}

.video-wrapper::before {
  content: "";
  position: absolute;
  width: 2px;
  height: 60%;
  background-color: #e5e5e5;
  right: -9px;
  top: 50%;
  transform: translateY(-50%);
}

.flip-list-move {
  transition: transform 0.5s;
}

.no-move {
  transition: transform 0s;
}

.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
</style>
