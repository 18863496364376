













































import { defineComponent, ref } from "vue";

export default defineComponent({
  setup() {
    const visible = ref(false);

    const toggle = () => (visible.value = !visible.value);

    return {
      visible,
      toggle
    };
  }
});
