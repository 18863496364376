<template>
  <tp-modal fullscreen name="modal-product" persistent>
    <v-card class="text-body-1 grey lighten-3">
      <v-overlay
        v-show="
          productOptionStatusRequest.value ===
            'loading-getProductOptionsByProductId' ||
            productOptionStatusRequest.value === 'loading-getCategoryAttributes'
        "
        opacity="0.5"
      >
        <v-progress-circular
          color="grey lighten-2"
          indeterminate
        ></v-progress-circular>
      </v-overlay>
      <!-- Start: Modal toolbar -->
      <modal-toolbar :form-data-thumbnail="formDataThumbnail"></modal-toolbar>
      <!-- End: Modal toolbar -->
      <!-- Start: Modal body -->
      <div class="col-scrollable">
        <card-general-info
          @updateFormDataThumbnail="formDataThumbnail = $event"
        ></card-general-info>

        <card-gallery></card-gallery>

        <card-setting-specs></card-setting-specs>

        <card-website-setting></card-website-setting>
      </div>

      <!-- End: Modal body -->
      <tp-modal-product-option></tp-modal-product-option>
    </v-card>
  </tp-modal>
</template>

<script>
import CardGeneralInfo from "./components/CardGeneralInfo";
import CardGallery from "./components/CardGallery";
import CardWebsiteSetting from "./components/CardWebsiteSetting";
import ModalToolbar from "./components/ModalToolbar";
import CardSettingSpecs from "./components/CardSettingSpecs.vue";
import { createVuexHelpers } from "@/core/composables";

export default {
  components: {
    CardSettingSpecs,
    CardGeneralInfo,
    CardGallery,
    CardWebsiteSetting,
    ModalToolbar
  },

  setup() {
    const { useActions } = createVuexHelpers();
    const { getColors } = useActions("COLOR", ["getColors"]);

    getColors();
  },

  data() {
    return {
      formDataThumbnail: null
    };
  },

  computed: {
    productOptionStatusRequest() {
      return this.$store.getters["PRODUCT_OPTION/statusRequest"];
    }
  }
};
</script>

<style lang="scss" scoped>
.col-scrollable {
  max-height: calc(100vh - 56px);
  overflow-y: scroll;
}
</style>
